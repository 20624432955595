import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/Layout';
import calendar from "../svg/calendar.svg";
import location from "../svg/location.svg";
import clock from "../svg/clock.svg";
import clipboardList from "../svg/clipboardList.svg";
import print from "../svg/print.svg";

const Jobs = () => (
  <div className="application">
    <Helmet>
      <title>Jobs | Automatiktür24</title>
      <meta charSet="utf-8" />
      <meta name="description" content="Automatiktür24 Jobs" />
      <html lang="de" />
    </Helmet>
    <Layout>

      <section className="pt-20">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/3 px-4">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
              Jobs
            </h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              Automatiktür24 GmbH
            </p>
            <p className="pb-10">
              Am Umspannwerk 19<br/>
              88255 Baindt
            </p>
            <p className="pb-10">
              Bewerbungen an:<br/>
              Manuel Brunner<br/>
              <a href="mailto:bewerbung@automatiktuer24.de">bewerbung@automatiktuer24.de</a><br/><br/>

              Tel. für Fragen: <a href="tel:+4975245092939">07524 / 509 29 39</a>
            </p>
          </div>

          <div className="lg:w-2/3">

            <div className="mb-24">
              <h2 className="text-3xl mb-6">Servicetechniker automatische Türanlagen (m/w/d)</h2>
              <div>
                <span className="flex m-3"><img src={calendar} alt="Start Termin" className="mr-3 w-6"/>Ab sofort</span>
              </div>
              <div>
                <span className="flex m-3"><img src={location} alt="Einsatzort" className="mr-3 w-6"/>88255 Baindt (bei Ravensburg), Baden-Württemberg</span>
              </div>
              <div>
                <span className="flex m-3"><img src={clock} alt="Arbeitszeit und Befristung" className="mr-3 w-6"/>Vollzeit, unbefristet</span>
              </div>
              <div>
                <span className="flex m-3"><img src={clipboardList} alt="Rahmenbedingungen" className="mr-3 w-6"/>Gehalt 3.000 - 4.000 €/Monat, Business Bike deiner Wahl, familienfreundliche Arbeitszeiten</span>
              </div>

              <div className="font-bold mt-8">Das erwartet Dich</div>
              <ul className="list-disc mt-4 ml-8 leading-relaxed">
                <li>Als Teil unseres jungen Teams übernimmst Du im technischen Außendienst die selbstständige Betreuung
                  unserer Kunden, der jährlichen Wartungs- und Sicherheitsprüfungen, Fehlerdiagnose und Reparatur von
                  automatischen Türanlagen wie Schiebetüren, Drehtürantrieben, Feststellanlagen, Rauchwärmeabzugsanlagen
                  und Glasschiebewänden. Unsere Produkte hierbei sind automatische Eingangssysteme
                  (z.B. automatische Schiebetüren, Drehtüren, Türantrieben und Feststellanlagen)</li>
                <li>Im Rahmen deiner Tätigkeit bist Du gemeinsam mit deinen Kolleg*innen aus Technik und Vertrieb für
                  die Abwicklung unserer Montage- und Serviceaufträge zuständig</li>
                <li>Du unterstützt auch telefonisch bei der Klärung von Service- und Reparaturaufträgen</li>
                <li>Du trägst die Verantwortung für den funktionierenden Mechanismus von Türsystemen</li>
              </ul>

              <div className="font-bold mt-8">Das bringst Du mit</div>
              <ul className="list-disc mt-4 ml-8 leading-relaxed">
                <li>Eine erfolgreich abgeschlossene Ausbildung als Mechatroniker, Elektriker, Anlagenmechaniker,
                  Elektrotechniker, Schlosser oder vergleichbar</li>
                <li>Du bist ein Teamplayer, kommunizierst gerne und hast eine professionelle Arbeitsweise</li>
                <li>Du arbeitest selbstständig, eigenverantwortlich und organisiert gerne</li>
                <li>Du arbeitest dich gerne in neue Themengebiete ein</li>
                <li>Idealerweise hast Du bereits erste Berufserfahrung in der Wartung, Montage und Inbetriebnahme von
                  elektrischen Türsystemen oder aus dem Bereich Fenster und Bauelemente und mit den gängigen
                  MS-Office Anwendungen (Word, Excel, Outlook)</li>
                <li>Einen gültigen Führerschein Klasse B, ehemals 3</li>
                <li>Gute Deutschkenntnisse in Wort und Schrift</li>
                <li>Wohnort in der Region Ulm, Biberach, Memmingen, Ravensburg</li>
              </ul>
            </div>

            {/*
            <div className="mt-24">
              <h2 className="text-2xl">Mitarbeiter*in Vertriebsinnendienst / Vertriebsassistent (m/w/d)</h2>
              <div>
                <span className="flex m-3"><img src={calendar} alt="Start Termin" className="mr-3 w-6 text-red-400 fill-blue-500"/>Ab sofort</span>
              </div>
              <div>
                <span className="flex m-3"><img src={location} alt="Einsatzort" className="mr-3 w-6 text-red-400 fill-blue-500"/>88255 Baindt (bei Ravensburg), Baden-Würrtemberg</span>
              </div>

              <div className="font-bold mt-8">Das erwartet Dich</div>
              <ul className="list-disc mt-4 ml-8 leading-relaxed">
                <li>Als Teil unseres jungen Teams im Innendienst erstellst Du Angebote für die Montage von automatischen
                  Eingangssystemen (z.B. automatische Schiebetüren oder Türantriebe für Drehtüren)</li>
                <li>Du unterstützt bei der Abwicklung unserer Neu- und Umbauprojekte und betreust hierbei Kunden bspw.
                  aus dem Handel, der Industrie oder kommunale Betriebe</li>
                <li>Im Rahmen deiner Tätigkeit bist Du gemeinsam mit deinen Kolleg*innen für die Planung und Abwicklung
                  unserer Montage- und Serviceaufträge zuständig. Hierzu gehört eine aktive Zusammenarbeit mit
                  unseren Servicetechnikern im Außendienst und Lieferanten</li>
                <li>Du unterstützt telefonisch bei der Aufnahme, Klärung und Abwicklung von Service- und
                  Reparaturaufträgen</li>
              </ul>

              <div className="font-bold mt-8">Das bringst Du mit</div>
              <ul className="list-disc mt-4 ml-8 leading-relaxed">
                <li>Eine abgeschlossene kaufmännische oder technische Ausbildung oder vergleichbar</li>
                <li>Du bist ein Teamplayer, kommunizierst gerne und hast ein professionelles Auftreten, insbesondere
                  gegenüber unseren Kunden</li>
                <li>Du arbeitest selbstständig, eigenverantwortlich und organisierst gerne</li>
                <li>Du arbeitest dich gerne in neue Themengebiete ein und hast Interesse daran die Funktionsweise
                  von automatischen Türen zu verstehen
                </li>
                <li>Idealerweise hast Du bereits erste Berufserfahrung im Büromanagement oder im Vertrieb gesammelt</li>
              </ul>
            </div>
            */}

            <div className="print:hidden">
              <a href="#" onClick={() => window.print()}><span className="flex m-3"><img src={print} alt="Drucken" className="mr-3 w-6"/>Drucken</span></a>
            </div>



          </div>
        </div>
      </section>

    </Layout>
  </div>
);

export default Jobs
